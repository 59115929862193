import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Form, ProgressBar } from "react-bootstrap";
import "./App.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Compass from "./compass";
import { useTranslation } from 'react-i18next';

const StepForm = ({steps,targetUrl,formId}) => {
  const { t,i18n} = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);

  };

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); 
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepFirst, setActiveStepFirst] = useState(true);
  const [formData, setFormData] = useState({});
  const [formDataPart, setFormDataPart] = useState({SiteDetails:{}});
  const [errors, setErrors] = useState({});
  const [isRadioError, setIsRadioError] = useState(false);
  const [isRadio, setIsRadio] = useState(false);
  const navigate = useNavigate();

    const [queryStrings, setQueryStrings] = useState([]);

    useEffect(() => {
      // Function to parse query string from URL
      const parseQueryString = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const lnagu = searchParams.get("lan")
        if(lnagu){
          changeLanguage(lnagu)
        }
        const queryStringObject = {};
        for (const [key, value] of searchParams.entries()) {
          queryStringObject[key] = value;
        }
        return queryStringObject;
      };
  
      // Call the function to parse query strings when component mounts
      setQueryStrings(parseQueryString());
    }, []);

  
  useEffect(() => {
    resetFormValues()
  }, [])
  
  useEffect(() => {
    console.log("new part ", formDataPart);
  }, [formDataPart]);

 const storeFormData = (answer) => {
  const urlParams = new URLSearchParams(window.location.search);

  // Create an object to store utm_* parameters
  const utmDetails = {};
  urlParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      utmDetails[key] = value;
    }
  });
   fetch(`${process.env.REACT_APP_API_URL}survey-forms`, {
     // fetch('http://localhost:4000/survey-forms', {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify({ answer, formId, queryStrings,selectedLanguage,utmDetails}),
   })
     .then((response) => response.json())
     .then((responseData) => {
       if (responseData.status === 201) {
         console.log("Response Data:", responseData.insertedId);
         const queryParams = `Fx=${formId}&Rx=${responseData.insertedId}`;

         // Construct the final URL with query parameters
         const isUS = window.location.href.includes("/us");
         const isUK = window.location.href.includes("/uk");
         const finalUrl = isUS
           ? `/us/response/form_one_64e59ece81a786fc0e09fa8a?${queryParams}`
           : (isUK ? `/uk/response/form_one_64e59ece81a786fc0e09fa8a?${queryParams}`: `/response/form_one_64e59ece81a786fc0e09fa8a?${queryParams}`);

         navigate(finalUrl);
       } else {
         alert("Request failed with status:", responseData.status);
       }
     })
     .catch((error) => {
       alert("Error posting data:", error.message);
     });
 };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };
  const handleNext = () => {
    if (validateForm()) {
      console.log("Active Step Value: ",activeStep)
      
    if (activeStep === 15) {
      let valueNew = formData["design_structure"]
        ? formData["design_structure"].trim()
        : "";
      if (valueNew === "Single Family House") {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
      } else if (
        formData["design_structure"] === undefined ||
        formData["design_structure"] === "undefined" ||
        formData["design_structure"] === null
      ) {
        // validateForm();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      
      console.log("form data",formData);
    }
    }
  };
  const handleFinish = () => {

    // if (validateForm()) {
      const newData = {};

      Object.keys(formData).forEach((key) => {
        const matchingStep = steps.find((step) =>
          step.fields.some((field) => field.name === key)
        );

        if (matchingStep) {
        //   const matchingField = matchingStep.fields.find(
        //     (field) => field.name === key
        //   );

          const label = matchingStep.label; // Get the label from the matching step
          newData[label] = formData[key]; // Use the label as the new key
        }
      });
    //   let formattedData = "";

      // Object.keys(newData).forEach((question) => {
      //   const answer = newData[question];
      //   formattedData += `Q: ${question}\nA: ${answer}\n\n`;
      // });
      var mergedObject = Object.assign({}, newData, formDataPart);
    

      storeFormData(mergedObject)
      // setActiveStep(steps.length - 1);
    // }
  };

  const handleBack = () => {
    setIsRadioError(false);
    
    if (activeStep === 17) {
      let valueNew = formData["design_structure"]
        ? formData["design_structure"].trim()
        : "";
      if (valueNew === "Single Family House") {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
      } else if (valueNew !== "Single Family House") {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    
  };

  const handleChange = (event) => {
    const { name, value, type} = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
console.log(type)
    if (type == "radio") {
      setIsRadio(true)
    }else{
            setIsRadio(false);

    }
  };

  const handleChangeSecond = (event) => {
    const { name, value ,type} = event.target;
    setFormDataPart((prevFormDataPart) => ({
        ...prevFormDataPart,
        SiteDetails: {
          ...prevFormDataPart.SiteDetails,
          [name]: value,
        },
      }));
    if (type == "radio") {
      setIsRadio(true);
    } else {
      setIsRadio(false);
    }
  };
useEffect(() => {
 console.log("before radio",formData)

 if(isRadio)
 {
  handleNext();
 }
}, [formData])

  const validateForm = () => {
    setErrors({});
    const currentStep = steps[activeStep];
    var isValid = true;
    setIsRadioError(false)
  
      const currentFields = currentStep.fields;

      currentFields.forEach((field) => {
  
        if (!formData[field.name] || formData[field.name].trim() === "") {
          console.log("inside black validation")
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field.name]: "This field is required",
          }));
          isValid = false;
          // if(field.type == 'radio'){
            setIsRadioError(true);
            return isValid;
          // }
        }else{
          
          if(activeStep == 0 ){
            var numVal = Number(formData["width"]);
            
          }else if(activeStep === 1){
            var numVal = Number(formData["depth"]);
          }
          if(numVal > 100){
              
            setErrors((prevErrors) => ({
              ...prevErrors,
              [field.name]: "Value must be 100 or less.",
            }));

            isValid = false;
            // if(field.type == 'radio'){
              setIsRadioError(true);
          return isValid;

          }
        
        }
      });
      return isValid;
  };

  const resetFormValues = () => {
    setFormData({});
    setErrors({});
  };

  const renderRadioOptions = (options, fieldName) => {
    return <div className="row" style={{padding:"0 10px"}}>{options.map((option, index) => {
      const uniqueId = `${fieldName}-${index}`;
      return (
        <div className='custom-radio-div'>
          <div
            key={uniqueId}
            className={`radio-option radio-option-name ${
              formData[fieldName] === option ? "active" : ""
            }`}
          >
            <Form.Check
              type="radio"
              id={uniqueId}
              name={fieldName}
              value={option}
              checked={formData[fieldName] === option}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              className="custom-radio"
            />
            <label htmlFor={uniqueId}>{t(option)}</label>
          </div>
        </div>
      );
    })}</div>
  };

  const renderRadioOptionsWithimages = (options, fieldName, fieldImages) => {
    console.log("images", fieldImages);

    return (
      <div className="image-radio-row">
        {options.map((option, index) => {
          const uniqueId = `${fieldName}-${index}`;
          return (
            <div className="custom-radio-div">
              <div
                key={uniqueId}
                className={`radio-option radio-option-image ${
                  formData[fieldName] === option ? "active" : ""
                }`}
              >
                <Form.Check
                  type="radio"
                  id={uniqueId}
                  name={fieldName}
                  value={option}
                  checked={formData[fieldName] === option}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  className="custom-radio"
                />
                <label htmlFor={uniqueId}>
                  {fieldImages ? (
                    fieldImages[index] ? (
                      // <img src={fieldImages[index]}  />
                      <div style={{ height: "9.5rem" }}>
                        <img src={fieldImages[index]} className="radio-image" alt="" />
                        <div>{t(option)}</div>
                      </div>
                    ) : (
                      t(option)
                    )
                  ) : (
                    t(option)
                  )}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    );
  };


  const renderRadioOptionsExtra = (options, fieldName,extra) => {
    return (
      <div className="row" style={{ padding: "0 8px" }} >
        {options.map((option, index) => {
          const uniqueId = `${fieldName}-${index}`;
          return (
            <div className="custom-radio-extra-div" key={uniqueId}>
              <div
                className={`radio-option-extra radio-option-extra-name ${
                  formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                }`}
              >
                <Form.Check
                  type="radio"
                  id={uniqueId}
                  name={fieldName}
                  value={option}
                  checked={formDataPart.SiteDetails[fieldName] === option}
                  onChange={handleChangeSecond}
                  onKeyDown={handleKeyPress}
                  className="custom-radio"
                />
                <label htmlFor={uniqueId}>{t(option)}</label>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderRadioOptionsWithimagesExtra = (options, fieldName, fieldImages,extra) => {
    console.log("images", fieldImages);

    return options.map((option, index) => {
      const uniqueId = `${fieldName}-${index}`;
      return (
        <div key={uniqueId} className="radio-option">
          <Form.Check
            type="radio"
            id={uniqueId}
            name={fieldName}
            value={option}
            checked={formDataPart.SiteDetails[fieldName] === option}
            onChange={handleChangeSecond}
            onKeyDown={handleKeyPress}
            
          />
          <label htmlFor={uniqueId}>
            {fieldImages ? (
              fieldImages[index] ? (
                // <img src={fieldImages[index]}  />
                <div>
                    <img src={fieldImages[index]} width={'100%;'} alt="" />
                    <div>{t(option)}</div>
                </div>
              ) : (
                t(option)
              )
            ) : (
              t(option)
            )}
          </label>
        </div>
      );
    }
    
    );
  };

 
  const startActivePage = () =>{
    setActiveStepFirst(false);
  }

  const totalSteps = steps.length;
  const progress = (activeStep / (totalSteps - 1)) * 100;

  return (
    <div className="container-fluid qwert">
      <div className="row w-100 imgH">
        {activeStepFirst ? (
          <div className="col-md-6 m-auto" style={{ padding: "0" }}>
            <div className="card p-2">
              <div className="card-body text-center">
                <h5 className="card-title mb-3" style={{ color: "white" }}>
                  <strong>Ai</strong> {t('ai title')}
                </h5>
                <h6 style={{ color: "white" }}>
                 {t("ai heading")}
                </h6>
                
                <button
                  type="button"
                  className="btn btn-primary mt-5 mb-5"
                  style={{ width: "auto" }}
                  onClick={startActivePage}
                >
                 {t("ai button")} 
                </button>
              </div>
            </div>
            <div className="language_select">
                  <a
                    
                    className=" mt-5 mb-5"
                    style={{ width: "auto",marginRight:"1rem",marginTop:"5px",marginBottom:"5px" }}
                    onClick={() => changeLanguage('en')}
                  >
                  English &nbsp;&nbsp;|
                  </a>
                  <a
                    
                    className=" mt-5 mb-5"
                    style={{ width: "auto",marginRight:"1rem",marginTop:"5px",marginBottom:"5px" }}
                    onClick={() => changeLanguage('hn')}
                  >
                  हिन्दी &nbsp;&nbsp;|
                  </a>
                  <a
                    
                    className=" mt-5 mb-5"
                    style={{ width: "auto",marginRight:"1rem",marginTop:"5px",marginBottom:"5px" }}
                    onClick={() => changeLanguage('ta')}
                  >
                  தமிழ் &nbsp;&nbsp;|
                  </a>

                  <a
                    
                    className=" mt-5 mb-5"
                    style={{ width: "auto",marginRight:"1rem",marginTop:"5px",marginBottom:"5px" }}
                    onClick={() => changeLanguage('tel')}
                  >
                  తెలుగు &nbsp;&nbsp;|
                  </a>

                  <a
                    
                    className=" mt-5 mb-5"
                    style={{ width: "auto",marginRight:"1rem",marginTop:"5px",marginBottom:"5px" }}
                    onClick={() => changeLanguage('mal')}
                  >
                  മലയാളം &nbsp;&nbsp;|
                  </a>

                  <a
                    
                    className=" mt-5 mb-5"
                    style={{ width: "auto",marginRight:"1rem",marginTop:"5px",marginBottom:"5px" }}
                    onClick={() => changeLanguage('kan')}
                  >
                  ಕನ್ನಡ 
                  </a>
                </div>
          </div>
        ) : (
          <div className="col-md-6 m-auto" style={{ padding: "0" }}>
            <ProgressBar now={progress} className="mb-1" />
            <div className="card p-2">
              <div className="card-body p-2">
                {activeStep === steps.length - 1 ? (
                  <>
                    <h5 className="card-title mb-1">
                      {t(steps[activeStep].label) }
                    </h5>
                    {steps[activeStep].smallText && (
                      <p className="card-title mb-0">
                        {t(steps[activeStep].smallText)}
                      </p>
                    )}
                    {steps[activeStep]?.label === "Site Details" ? (
                      <Compass
                        showValue={false}
                        selectedValue={formData['direction'] || "direction"}
                      />
                    ) : (
                      ""
                    )}
                    <div className="row">
                      {steps[activeStep].fields.map((field) => (
                        <>
                          <Form.Group
                            key={field.name}
                            controlId={field.name}
                            className={
                              field.extra === undefined
                                ? "col-md-12"
                                : "col-sm-6"
                            }
                          >
                            <h6
                              className="card-title mb-1"
                              style={{ color: "white" }}
                            >
                              {field.label}
                            </h6>

                            {field.type === "radio" ? (
                              <div>
                                {field.extra === undefined
                                  ? field.images === undefined
                                    ? renderRadioOptions(
                                        field.options,
                                        field.name
                                      )
                                    : renderRadioOptionsWithimages(
                                        field.options,
                                        field.name,
                                        field.images
                                      )
                                  : field.images === undefined
                                  ? renderRadioOptionsExtra(
                                      field.options,
                                      field.name,
                                      field.extra
                                    )
                                  : renderRadioOptionsWithimagesExtra(
                                      field.options,
                                      field.name,
                                      field.images,
                                      field.extra
                                    )}
                              </div>
                            ) : (
                              <Form.Control
                                type={field.type}
                                name={field.name}
                                value={formData[field.name] || ""}
                                onChange={handleChange}
                                onKeyDown={handleKeyPress}
                              />
                            )}
                          </Form.Group>
                        </>
                      ))}
                    </div>
                    {isRadioError && (
                      <div style={{ color: "red" }}>
                        {t("This field is required")}
                      </div>
                    )}
                    <div
                      className="mt-3 row flex"
                      style={{ border: "unset", background: "unset" }}
                    >
                      <div className="col text-left">
                        {activeStep !== 0 && (
                          <Button
                            variant="outline-light"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            <NavigateBeforeIcon /> {t("Back")}
                          </Button>
                        )}
                      </div>
                      <div className="col text-right">
                        <Button
                          className="ml-2"
                          variant="light"
                          onClick={handleFinish}
                        >
                          {t("Finish")}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h5 className="card-title mb-3">
                      {t(steps[activeStep].label)}
                    </h5>
                    {steps[activeStep].smallText && (
                      <p className="card-title mb-0">
                        {t(steps[activeStep].smallText)}
                      </p>
                    )}

                    <div className="row">
                      {steps[activeStep].fields.map((field) => (
                        <Form.Group
                          key={field.name}
                          controlId={field.name}
                          className={
                            field.extra === undefined ? "col-md-12" : "col-sm-6"
                          }
                        >
                          {field.type === "compass" ? (
                            <Compass
                              showValue={true}
                              selectedValue={formData[field?.name] || ""}
                              setDirectionInForm={handleChange}
                            />
                          ) : field.type === "radio" ? (
                            <div>
                              {field.extra === undefined
                                ? field.images === undefined
                                  ? renderRadioOptions(
                                      field.options,
                                      field.name
                                    )
                                  : renderRadioOptionsWithimages(
                                      field.options,
                                      field.name,
                                      field.images
                                    )
                                : field.images === undefined
                                ? renderRadioOptionsExtra(
                                    field.options,
                                    field.name,
                                    field.extra
                                  )
                                : renderRadioOptionsWithimagesExtra(
                                    field.options,
                                    field.name,
                                    field.images,
                                    field.extra
                                  )}
                            </div>
                          ) : (
                            <Form.Control
                              type={field.type}
                              name={field.name}
                              value={formData[field.name] || ""}
                              onChange={handleChange}
                              onKeyDown={handleKeyPress}
                            />
                          )}
                          {/* <Form.Control.Feedback type="invalid" style={{display:'block!important'}}>
                            {errors[field.name]}
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      ))}
                    </div>
                    {isRadioError && (
                      <div style={{ color: "red" }} className="somi2">
                        {t(errors.width ? errors.width : (errors.depth ? errors.depth : "This field is required"))}
                      </div>
                    )}
                    <div
                      className="mt-3 row flex"
                      style={{ border: "unset", background: "unset" }}
                    >
                      <div className="col text-left">
                        {activeStep !== 0 && (
                          <Button
                            variant="outline-light"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            <NavigateBeforeIcon /> {t("Back")}
                          </Button>
                        )}
                      </div>
                      <div className="col text-right">
                        <Button
                          className="ml-2"
                          variant="light"
                          onClick={
                            activeStep === steps.length - 1
                              ? handleFinish
                              : handleNext
                          }
                        >
                          {activeStep === steps.length - 1 ? (
                            "Finish"
                          ) : (
                            <>
                              {t("Next")}
                              <NavigateNextIcon />
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StepForm;
