import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
const InfoModal = ({
  show,
  change,
  min,
  sec,
  timer,
  payUrl,
  apiCall,
  discount,
  setShowThree,
  setShowFour,
}) => {
  const { t} = useTranslation();
  return (
    <>
      <Modal
        className="modal fade modal-bottom-to-top"
        id="lab-slide-bottom-popup"
        show={show}
        onHide={change}
        backdropClassName="modal-backdrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>{" "}
        </Modal.Header>
        <div className="lab-modal-body">
          <div className="content_new">
            <h1>{t("Did you know?")}</h1>
            <p className="desc">
              {t("DidYouKnowTitle")}
            </p>
            <div className="box_img">
              <img
                loading="lazy"
                src="https://mailer-assets.makemyhouse.com/form-type-images/family-with-home.png"
                alt="Third slide"
              />
            </div>
            <p className="desc_new_c">
              {t("We want to bring your dream home to life so we are offering an")}
              <span className="bolder-f">{t("additional")} {discount}% </span>{t("discount on your")} <span className="bolder-f">{t("faq5ans3H")}</span> {t("package")}.
            </p>
            
            {timer ? (
              <>
                <div>
                  {t("Time Remaining")}: {min}:{sec}
                </div>
                <a
                //   onClick={() => apiCall(payUrl, "Offer")}
                  onClick={() => {setShowFour(true);
                  setShowThree(false);
                  }}
                  id="firstPayment"
                  className="cursor_pointer"
                >
                  <button className="btn btn-sm btn_pay w-100">
                    {t("Grab Offer Now")}
                  </button>
                </a>
              </>
            ) : (
              <>
                <div>
                  {t("Time Remaining")}: 0:0
                </div>
                <a
                  onClick={change}
                    id="firstPayment"
                    className="cursor_pointer"
                  >
                <button
                  className="btn btn-sm btn_pay w-100 mt-2"
                  
                >
                  {t("Get Plan")}
                </button>
              </a>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default InfoModal;
