import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import "./payPage.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OverlayTrigger, Popover, Collapse } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import InfoModal from "./InfoModal";
import DiscountModal from "./DiscountModal";
import { useTranslation } from 'react-i18next';

const StepThree = () => {
  const { t,i18n } = useTranslation();
  const changeLanguage = (lng) => {
    setIsOpen(!isOpen);
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);

  };
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); 
  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { slug } = useParams();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{t("You will receive one Floor Plan option.")}</Popover.Body>
    </Popover>
  );

  const popoverSecond = (
    <Popover id="popover-basic-second">
      <Popover.Body>
        {t("You will receive Entirely different Two Floor Plan options")}
      </Popover.Body>
    </Popover>
  );

  const popoverThired = (
    <Popover id="popover-basic-second">
      <Popover.Body>
        {t("You will receive One Floor Plan + 3D Elevation options")}
      </Popover.Body>
    </Popover>
  );

  const popoverFourth = (
    <Popover id="popover-basic-second">
      <Popover.Body>
        {t("You will receive Entirely different Two Floor Plan + 3D Elevation options")}
      </Popover.Body>
    </Popover>
  );

  const searchParams = new URLSearchParams(location.search);
  const isd = searchParams.get("isd") || "91";
  const mobile = searchParams.get("mobile");
  const responseId = searchParams.get("Rx");

  // const formattedIsd = isd?.replace("+", "");


  // For PaymentLink
  const [payURL, setPayURL] = useState(null);
  const [secondPayUrl, setSecondPayUrl] = useState();
  const [thiredPayUrl, setThiredPayUrl] = useState();
  const [fourthPayUrl, setFourthPayUrl] = useState();
  const [offerPayUrl, setOfferPayUrl] = useState();
  // End
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);

  const [showFive, setShowFive] = useState(false);
  const handleCloseFive = () => setShowFive(false);
  const handleShowFive = () => setShowFive(true);

  const [showSix, setShowSix] = useState(false);
  const handleCloseSix = () => setShowSix(false);
  const handleShowSix = () => setShowSix(true);

  const [showSeven, setShowSeven] = useState(false);
  const handleCloseSeven = () => setShowSeven(false);
  const handleShowSeven = () => setShowSeven(true);

  // For 3D-Elevation 
  const [showD, setShowD] = useState(false);
  const handleCloseD = () => setShowD(false);
  const handleShowD = () => setShowD(true);

  const [showTwoD, setShowTwoD] = useState(false);
  const handleCloseTwoD = () => setShowTwoD(false);
  const handleShowTwoD = () => setShowTwoD(true);

  const [showSixD, setShowSixD] = useState(false);
  const handleCloseSixD = () => setShowSixD(false);
  const handleShowSixD = () => setShowSixD(true);

  const [showSevenD, setShowSevenD] = useState(false);
  const handleCloseSevenD = () => setShowSevenD(false);
  const handleShowSevenD = () => setShowSevenD(true);
  // End 3D- Elevation 
  // For Did You Know Modal Handlers
  const [showThree, setShowThree] = useState(false);
  const handleShowThree = () => setShowThree(true);
  const handleCloseThree = () => setShowThree(false);
  //  End
  // For Offer Modal Handlers
  const [showFour, setShowFour] = useState(false);
  const handleShowFour = () => setShowFour(true);
  const handleCloseFour = () => setShowFour(false);
  // End

  const [data, setData] = useState(null);
  const [futureDate, setFutureDate] = useState(null);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {

    window.gtag?.("event", "conversion", {
      send_to: "AW-773249637/TUjbCPWisZIZEOW02_AC",
    });

    const currentDate = new Date();
    const futureDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    setFutureDate(futureDate);
  }, []);

  // Make the API call for CheckOut Details
  const getCheckOutDetails = (currency) => {
    fetch(
      `${process.env.REACT_APP_API_URL}checkout-details?&currency=${currency}&slug=${slug}`
    )
      .then((response) => response.json())
      .then((resultData) => {
        console.log("data from api call", resultData);
        const isUS = window.location.href.includes("/us");
        const token = localStorage.getItem('authToken');
        // For First Offer payment Link
        const url = `${process.env.REACT_APP_PAYMENT_WEB_URL}/payment-flow?offertype=Option1&currency=${
          resultData.currency
        }&productid=${resultData.firstProductId}&entity=${resultData.entity}&token=${token}&lang=${selectedLanguage}&amount=${resultData.after_offer_amount}&mobile=${mobile}&responseId=${responseId}&isdcode=${isd?.replace(
          "+",
          ""
        )}&brandCode=${resultData.brandCode}`;

        // For Second Offer
        if (resultData.second_after_offer_amount) {
          const url_second = `${process.env.REACT_APP_PAYMENT_WEB_URL}/payment-flow?offertype=Option2&currency=${
            resultData.currency
          }&productid=${resultData.secondProductId}&entity=${resultData.entity}&token=${token}&lang=${selectedLanguage}&amount=${
            resultData.second_after_offer_amount
          }&mobile=${mobile}&responseId=${responseId}&isdcode=${isd?.replace(
            "+",
            ""
          )}&brandCode=${resultData.brandCode}`;

          // For discount Offer
          const offer_url = `${process.env.REACT_APP_PAYMENT_WEB_URL}/payment-flow?offertype=Option1_2D_3D_Offer&currency=${
            resultData.currency
          }&productid=${resultData.secondOfferProductId}&entity=${resultData.entity}&token=${token}&lang=${selectedLanguage}&amount=${resultData.offer_prize}&mobile=${mobile}&responseId=${responseId}&isdcode=${isd?.replace(
            "+",
            ""
          )}&brandCode=${resultData.brandCode}`;

          setSecondPayUrl(url_second);
          setOfferPayUrl(offer_url);
        }

        // For Thired Offer 3D Single Plan Option 
        if (resultData.ele_after_offer_amount) {
          const url_ele = `${process.env.REACT_APP_PAYMENT_WEB_URL}/payment-flow?offertype=Option1_2D_3D&currency=${
            resultData.currency
          }&productid=${resultData.thiredProductId}&entity=${resultData.entity}&token=${token}&lang=${selectedLanguage}&amount=${
            resultData.ele_after_offer_amount
          }&mobile=${mobile}&responseId=${responseId}&isdcode=${isd?.replace(
            "+",
            ""
          )}&brandCode=${resultData.brandCode}`;
          setThiredPayUrl(url_ele);
        }

        // For Fourth Offer 3D Double Plan Option 
        if (resultData.second_ele_after_offer_amount) {
          const url_ele_second = `${process.env.REACT_APP_PAYMENT_WEB_URL}/payment-flow?offertype=Option2_2D_3D&currency=${
            resultData.currency
          }&productid=${resultData.fourthProductId}&entity=${resultData.entity}&token=${token}&lang=${selectedLanguage}&amount=${
            resultData.second_ele_after_offer_amount
          }&mobile=${mobile}&responseId=${responseId}&isdcode=${isd?.replace(
            "+",
            ""
          )}&brandCode=${resultData.brandCode}`;
          setFourthPayUrl(url_ele_second);
        }


        setPayURL(url);
        setData(resultData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

   const updatePlaneInResponse = (locationUrl, packageType) => {

    const urlParams = new URLSearchParams(locationUrl.split('?')[1]);
    const mobileValue = urlParams.get('mobile');
    const responseIdValue = urlParams.get('responseId');
    console.log('mobile : ',mobileValue);
    console.log("responseId : ",responseIdValue);
    // Check if mobile or responseId is blank
    if (!mobileValue || mobileValue.length <= 7 || !responseIdValue) {
      alert("Unable to process please try again.");
      navigate('/');
      return; 
    }

     fetch(`${process.env.REACT_APP_API_URL}update/${responseId}`, {
       // fetch(`http://localhost:4000/update/${responseId}`, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({ packageType }),
     })
       .then((response) => response.json())
       .then((responseData) => {
         console.log("response update api: ", responseData);
       });
     console.log("package Type: ", packageType);
     window.location.href = locationUrl;
   };
  useEffect(() => {
    var currency;
    // console.log("isd", isd.includes("91"));
    // if (isd === "+91") {
    if (isd?.includes("91")) {
      currency = "INR";
    } else {
      currency = "USD";
    }
    console.log("currency", currency);
    if (currency) {
      if(window.location.href.includes("/us")){
        currency = "USD"
      }else if(window.location.href.includes("/uk")){
        currency = "EUR"
      }
      getCheckOutDetails(currency);
    }
  }, []);
  // This is For Plans
  const [planValue, setPlanValue] = useState("one");

  const handleGroup1Change = (event) => {
    console.log("plan value: ",event.target.value)
    setPlanValue(event.target.value);
  };
  // End

  // For Event On Browser Back Button
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(600); // 10 minutes in seconds
  const [offerCookie, setOfferCookie] = useState(
    localStorage.getItem("offerCookie") || "0"
  );

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  useEffect(() => {
    //  localStorage.setItem("offerCookie", "0");
    if (!localStorage.getItem("timerLastRunDate")) {
      console.log("iam inn");
      localStorage.setItem("offerCookie", "0");
    }
    const RemainingTime = localStorage.getItem("RemainingTimerTime");
    if (RemainingTime) {
      setTime(RemainingTime);
      setIsRunning(true);
    }
  }, []);

  useEffect(() => {
    let timerId;
    // console.log("isRunning", isRunning, "time", time);
    if (isRunning && time > 0) {
      timerId = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        localStorage.setItem("RemainingTimerTime", time);
      }, 1000);
    } else if (time === 0) {
      setIsRunning(false);
    }
    return () => clearInterval(timerId);
  }, [isRunning, time]);

  useEffect(() => {
    if (localStorage.getItem("offerCookie")) {
      console.log("offer value cookie", localStorage.getItem("offerCookie"));
      setOfferCookie(localStorage.getItem("offerCookie"));
    }
  }, [localStorage.getItem("offerCookie")]);

  const preventBack = () => {
    const backEventTrace = offerCookie;
    console.log(
      "Back button pressed. offerCookie:",
      backEventTrace,
      "Time:",
      time
    );

    if (time == 0) {
      console.log("timer running and offer");
      setShowThree(true);
      setShowFour(false);
    } else {
      if (backEventTrace == "0") {
        console.log("timer running and offer valyue 0 ");
        localStorage.setItem("offerCookie", "1");
        if (!isRunning) {
          const currentDate = new Date().toLocaleDateString();
          const currentTime = new Date().getTime();
          if (!localStorage.getItem("timerLastRunDate")) {
            localStorage.setItem("timerLastRunDate", currentDate);
          }

          if (!localStorage.getItem("timerLastRunTime")) {
            localStorage.setItem("timerLastRunTime", currentTime);
          }
          setIsRunning(true);
        }

        const currentDate = new Date().toLocaleDateString();
        const currentTime = new Date().getTime();

        const lastRunDate = localStorage.getItem("timerLastRunDate") || "";
        const lastRunTime =
          parseInt(localStorage.getItem("timerLastRunTime")) || 0;

        const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

        if (
          currentDate <= lastRunDate &&
          currentTime <= lastRunTime + TEN_MINUTES
        ) {
          setShowThree(false);
          setShowFour(true);
        }else{
          setIsRunning(false)
           setShowThree(true);
           setShowFour(false);
        }
      } else if (backEventTrace == "1") {
        console.log("timer running and offer valyue 1 ");
        localStorage.setItem("offerCookie", "0");
        const currentDate = new Date().toLocaleDateString();
        const currentTime = new Date().getTime();

        const lastRunDate = localStorage.getItem("timerLastRunDate") || "";
        const lastRunTime =
          parseInt(localStorage.getItem("timerLastRunTime")) || 0;

        const TEN_MINUTES = 10 * 60 * 1000; // 30 minutes in milliseconds

        if (
          currentDate <= lastRunDate &&
          currentTime <= lastRunTime + TEN_MINUTES
        ) {
          setShowThree(true);
          setShowFour(false);
        } else {
          setIsRunning(false);
          setShowThree(true);
          setShowFour(false);
        }
      }
    }
  };

 

  useEffect(() => {
    localStorage.setItem(
      "isBackCheckoutUrl",
      window.location.pathname + window.location.search
    );
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem("isRedirected") == 1) {
      console.log("page redirected from login.... ");
      preventBack();
    }
  }, []);
  useEffect(() => {
    console.log("showFour ", showFour, "showThree ", showThree);
  }, [showFour,showThree]);

  // End

  return (
    <>
      {data !== null && (
        <section className="w-100 main-section">
            <div className="header">
              <a className="drop-down-ai dropdown-toggle" onClick={toggleDropdown} style={{ marginRight: '1rem', marginTop: '5px', marginBottom: '5px' }}>
                Select Language
              </a>
              
              {isOpen && (
                <div className="dropdown-flex ">
                  <a 
                    style={{
                      marginRight: '1rem',
                      marginTop: '5px',
                      marginBottom: '5px',
                      fontWeight: selectedLanguage === 'en' ? 'bold' : 'normal',
                      color: selectedLanguage === 'en' ? '#4caf50 !important' : 'inherit',
                    }} 
                    onClick={() => changeLanguage('en')}>
                    English
                  </a>
                  <a 
                      style={{
                        marginRight: '1rem',
                        marginTop: '5px',
                        marginBottom: '5px',
                        fontWeight: selectedLanguage === 'hn' ? 'bold' : 'normal',
                        color: selectedLanguage === 'hn' ? '#4caf50' : 'inherit',
                      }} 
                      onClick={() => changeLanguage('hn')}>
                    हिन्दी
                  </a>
                  <a 
                      style={{
                        marginRight: '1rem',
                        marginTop: '5px',
                        marginBottom: '5px',
                        fontWeight: selectedLanguage === 'ta' ? 'bold' : 'normal',
                        color: selectedLanguage === 'ta' ? '#4caf50' : 'inherit',
                      }} 
                      onClick={() => changeLanguage('ta')}>
                    
                    தமிழ்
                  </a>

                  <a 
                      style={{
                        marginRight: '1rem',
                        marginTop: '5px',
                        marginBottom: '5px',
                        fontWeight: selectedLanguage === 'tel' ? 'bold' : 'normal',
                        color: selectedLanguage === 'tel' ? '#4caf50' : 'inherit',
                      }} 
                      onClick={() => changeLanguage('tel')}>
                    
                    తెలుగు
                  </a>

                  <a 
                      style={{
                        marginRight: '1rem',
                        marginTop: '5px',
                        marginBottom: '5px',
                        fontWeight: selectedLanguage === 'mal' ? 'bold' : 'normal',
                        color: selectedLanguage === 'mal' ? '#4caf50' : 'inherit',
                      }} 
                      onClick={() => changeLanguage('mal')}>
                    
                    മലയാളം
                  </a>

                  <a 
                      style={{
                        marginRight: '1rem',
                        marginTop: '5px',
                        marginBottom: '5px',
                        fontWeight: selectedLanguage === 'kan' ? 'bold' : 'normal',
                        color: selectedLanguage === 'kan' ? '#4caf50' : 'inherit',
                      }} 
                      onClick={() => changeLanguage('kan')}>
                    
                    ಕನ್ನಡ
                  </a>
                  </div>
                )}
            </div>
          <div className="col-sm-3 main-page-con m-auto">
            <div className="row">
              <div className="col-sm-12 mb-3 main-slider">
                <Carousel data-bs-theme="dark">
                  <Carousel.Item>
                    <img
                      loading="lazy"
                      className="d-block w-100"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/banner-1.webp?version=222"
                      alt="First slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      loading="lazy"
                      className="d-block w-100"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/banner-2.webp?version=222"
                      alt="Second slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      loading="lazy"
                      className="d-block w-100"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/banner-3.webp?version=222"
                      alt="Third slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      loading="lazy"
                      className="d-block w-100"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/banner-4.webp?version=222"
                      alt="Third slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>

              <section className="price-main mt-3">
                <div className="" style={{ padding: "0px 10px 20px 10px" }}>
                  <div className="col-12">
                    <div className="heading mt-0">
                      <h3>
                        <b>{t("Your 2D Floor Plan is ready !")}</b>
                      </h3>
                    </div>
                    <p className="mb-3">
                      {t("2d Heading")}
                    </p>
                    <p className="mb-1">
                      <strong>{t("Delivery Time")} :</strong> {t("Within 4 working hours")}
                    </p>
                    <p className="mb-1">
                      <strong>{t("Delivery Via")} :</strong> {t("Softcopy (Email , Whatsapp)")}
                    </p>
                  </div>
                  <div className="plans mt-3 text center">
                    {/* For First Plane  */}
                    <label className="plan basic-plan" htmlFor="basic">
                      <input
                        checked={planValue === "one"}
                        type="radio"
                        name="plan"
                        id="basic"
                        value="one"
                        onChange={handleGroup1Change}
                      />
                      <div className="plan-content">
                        <div className="offer_flex">
                            <div className="bluprint-ai">
                              <p className="infotitle_ai">
                              {t("faq5ansH")}
                              </p>
                              <OverlayTrigger
                                
                                placement="bottom"
                                overlay={popover}
                              >
                                <a variant="secondary">
                                  <InfoOutlinedIcon
                                    style={{
                                      fontSize: "14px",
                                      marginLeft: "4px",
                                    }}
                                  ></InfoOutlinedIcon>
                                </a>
                              </OverlayTrigger>
                            </div>
                          <div className="col-5_ai">
                            <p className="title_price">
                              <strong>{t("One design option Plan")}</strong>
                            </p>
                          </div>

                          <div className="col-7_ai">
                            <div className="price showprice d-flex">
                              <div className="price_f_1">
                                {data.symbol}
                                {data.after_offer_amount}
                              </div>{" "}
                              &nbsp;{" "}
                              <s style={{ color: "#fff" }}>
                                {data.symbol}
                                {data.original_amount}
                              </s>
                              <div className="prsnt"> {data.discount}%</div>{" "}
                              <font>{t("OFF")}</font>
                              <span
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                    {/* End First Plan Div  */}
                    {/* For Thired Plane  */}
                    {data.ele_after_offer_amount && (
                          <div id="collapse-second-plan">
                            <label
                              className="plan complete-plan"
                              htmlFor="complete2"
                            >
                              <input
                                type="radio"
                                checked={planValue === "three"}
                                id="complete2"
                                name="plan"
                                value="three"
                                onChange={handleGroup1Change}
                              />
                              <div className="plan-content">
                                <div className="offer_flex">
                                    <div className="bluprint-ai">
                                      <p className="infotitle_ai">
                                      {t("faq5ans3H")}
                                      </p>
                                        <OverlayTrigger
                                          
                                          placement="bottom"
                                          overlay={popoverThired}
                                        >
                                          <a variant="secondary">
                                            <InfoOutlinedIcon
                                              style={{
                                                fontSize: "14px",
                                                marginLeft: "4px",
                                              }}
                                            ></InfoOutlinedIcon>
                                          </a>
                                        </OverlayTrigger>
                                    </div>
                                  <div className="col-5_ai">
                                    <p className="title_price">
                                      <strong>{t("One(2D Design + 3D Elevation) option plan")}</strong>
                                    </p>
                                    
                                  </div>

                                  <div className="col-7_ai">
                                    <div className="price showprice d-flex">
                                      <div className="price_f_2">
                                        {data.symbol}
                                        {data.ele_after_offer_amount}
                                      </div>{" "}
                                      &nbsp;{" "}
                                      <s style={{ color: "#fff" }}>
                                        {data.symbol}
                                        {data.ele_original_amount}
                                      </s>
                                      <div className="prsnt">
                                        {" "}
                                        {data.ele_discount}%
                                      </div>{" "}
                                      <font>{t("OFF")}</font>
                                    </div>
                                    <p className="tag">{t("Best Seller")}</p>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                      )}

                    {/* End Thired Plan Div  */}
                    <style>
                      {`
                        .right-float-white {
                          color: white !important;
                          display: flex;
                          justify-content: end;
                          align-items: center;
                        }
                        .cursor_pointer{
                          cursor:pointer !important;
                        }
                      `}
                    </style>
                    <div
                      onClick={() => setOpen(!open)}
                      aria-controls="collapse-second-plan"
                      aria-expanded={open}
                      className="right-float-white cursor_pointer"
                    >
                      +{window.location.href.includes("/us") || window.location.href.includes("/uk") ? t("1 more") : t("3 more")}
                    </div>

                    {/* For Second Plane  */}
                    {data.second_after_offer_amount && (
                      <Collapse in={open}>
                        <div id="collapse-second-plan">
                          <label
                            className="plan complete-plan"
                            htmlFor="complete"
                          >
                            <input
                              type="radio"
                              checked={planValue === "two"}
                              id="complete"
                              name="plan"
                              value="two"
                              onChange={handleGroup1Change}
                            />
                            <div className="plan-content">
                              <div className="offer_flex">
                                  <div className="bluprint-ai">
                                    <p className="infotitle_ai">
                                    {t("faq5ans2H")}
                                    </p>
                                      <OverlayTrigger
                                        
                                        placement="bottom"
                                        overlay={popoverSecond}
                                      >
                                        <a variant="secondary">
                                          <InfoOutlinedIcon
                                            style={{
                                              fontSize: "14px",
                                              marginLeft: "4px",
                                            }}
                                          ></InfoOutlinedIcon>
                                        </a>
                                      </OverlayTrigger>
                                  </div>
                                <div className="col-5_ai">
                                  <p className="title_price">
                                    <strong>{t("Two design option Plan")}
                                    </strong>
                                  </p>
                                </div>

                                <div className="col-7_ai">
                                  <div className="price showprice d-flex">
                                    <div className="price_f_3">
                                      {data.symbol}
                                      {data.second_after_offer_amount}
                                    </div>{" "}
                                    &nbsp;{" "}
                                    <s style={{ color: "#fff" }}>
                                      {data.symbol}
                                      {data.second_original_amount}
                                    </s>
                                    <div className="prsnt">
                                      {" "}
                                      {data.second_discount}%
                                    </div>{" "}
                                    <font>{t("OFF")}</font>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </Collapse>
                    )}

                    {/* End Second Plan Div  */}



                    {/* For Fourth Plane  */}
                      {data.ele_after_offer_amount && (
                        <Collapse in={open}>
                          <div id="collapse-second-plan">
                            <label
                              className="plan complete-plan"
                              htmlFor="complete4"
                            >
                              <input
                                type="radio"
                                checked={planValue === "four"}
                                id="complete4"
                                name="plan"
                                value="four"
                                onChange={handleGroup1Change}
                              />
                              <div className="plan-content">
                                <div className="offer_flex">
                                    <div className="bluprint-ai">
                                      <p className="infotitle_ai">
                                      {t("faq5ans4H")}
                                      </p>
                                        <OverlayTrigger
                                          
                                          placement="bottom"
                                          overlay={popoverFourth}
                                        >
                                          <a variant="secondary">
                                            <InfoOutlinedIcon
                                              style={{
                                                fontSize: "14px",
                                                marginLeft: "4px",
                                              }}
                                            ></InfoOutlinedIcon>
                                          </a>
                                        </OverlayTrigger>
                                    </div>
                                  <div className="col-5_ai">
                                    <p className="title_price">
                                      <strong>{t("Two(2D Design + 3D Elevation) option plan")}
                                      </strong>
                                    </p>
                                  </div>

                                  <div className="col-7_ai">
                                    <div className="price showprice d-flex">
                                      <div className="price_f_4">
                                        {data.symbol}
                                        {data.second_ele_after_offer_amount}
                                      </div>{" "}
                                      &nbsp;{" "}
                                      <s style={{ color: "#fff" }}>
                                        {data.symbol}
                                        {data.second_ele_original_amount}
                                      </s>
                                      <div className="prsnt">
                                        {" "}
                                        {data.second_ele_discount}%
                                      </div>{" "}
                                      <font>{t("OFF")}</font>
                                    </div>
                                    <p className="super_tag">{t("Super Saver")}</p>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Collapse>
                      )}

                    {/* End Fourth Plan Div  */}
                  </div>
                  <div className="col-12 ">
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        color: "#14f7c1",
                      }}
                    >({t("Offer Valid Till")} {formatDate(futureDate)} {t("Till")})</p>
                  </div>
                  <div className="col-12">
                    <div className="price w-100">
                      {/* <a className="showprice w-100" href={payURL}>
                        <button className="btn btn-sm btn_pay w-100">
                          Pay Now
                        </button>
                      </a> */}
                      {planValue === "one" && (
                        <span
                          className="showprice w-100"
                          onClick={() =>
                            updatePlaneInResponse(payURL, "Option1")
                          }
                        >
                          <button className="btn btn-sm btn_pay w-100">
                            {t("Pay Now")}
                          </button>
                        </span>
                      )}

                      {planValue === "two" && (
                        <span
                          className="showprice w-100"
                          onClick={() =>
                            updatePlaneInResponse(secondPayUrl, "Option2")
                          }
                        >
                          <button className="btn btn-sm btn_pay w-100">
                          {t("Pay Now")}
                          </button>
                        </span>
                      )}

                      {planValue === "three" && (
                        <span
                          className="showprice w-100"
                          onClick={() =>
                            updatePlaneInResponse(thiredPayUrl, "Option1_2D_3D")
                          }
                        >
                          <button className="btn btn-sm btn_pay w-100">
                          {t("Pay Now")}
                          </button>
                        </span>
                      )}

                      {planValue === "four" && (
                        <span
                          className="showprice w-100"
                          onClick={() =>
                            updatePlaneInResponse(fourthPayUrl, "Option2_2D_3D")
                          }
                        >
                          <button className="btn btn-sm btn_pay w-100">
                          {t("Pay Now")}
                          </button>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <div className="advantages p-3 pt-0 pb-0">
                <div className="heading">
                  <h2 className="text-center">
                    <b>{t("Advantages")}</b>
                  </h2>
                </div>
                <div className="row mt-4">
                  <div className="col-6 text-center">
                    <div className="advantages-bx">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/ic-1.png"
                        className="img-fluid"
                        alt=""
                      ></img>
                      <h6>
                        {t("Cost Effective")} 
                      </h6>
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="advantages-bx">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/ic-2.png"
                        className="img-fluid"
                        alt=""
                      ></img>
                      <h6>{t("High Accuracy (95%)")}</h6>
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="advantages-bx">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/ic-3.png"
                        className="img-fluid"
                        alt=""
                      ></img>
                      <h6>
                        {t("Instant Delivery")}
                      </h6>
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="advantages-bx">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/ic-4.png"
                        className="img-fluid"
                        alt=""
                      ></img>
                      <h6>
                        {t("Multiple Design options")}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <section className="w-100 mt-3 mb-3">
                {/* DID You Know Modal Start  */}
                <InfoModal
                  show={showThree}
                  setShowThree={setShowThree}
                  setShowFour={setShowFour}
                  change={handleCloseThree}
                  min={minutes}
                  sec={seconds}
                  timer={isRunning}
                  payUrl={offerPayUrl}
                  discount = {data.offer_discount}
                  apiCall={updatePlaneInResponse}
                />
                {/* DID You Know Modal End  */}
                {/* Offer modal Start */}
                <DiscountModal
                  show={showFour}
                  change={handleCloseFour}
                  min={minutes}
                  sec={seconds}
                  payUrl={offerPayUrl}
                  base_prize={data.ele_after_offer_amount}
                  offer_prize={data.offer_prize}
                  symbol={data.symbol}
                  apiCall={updatePlaneInResponse}
                  timer={isRunning}
                />
                {/* Offer modal End */}




                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="heading">
                        <h2 className="text-center">
                          <b>{t("Sample Delivery Plans")}</b>
                        </h2>
                      </div>
                    </div>
                    <div className="col-12 mb-3 item_ai_slider">
                    <Carousel data-bs-theme="dark"
                      interval={null}
                    >
                      <Carousel.Item >
                          <div>
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-1.webp"
                              alt="Image 1"
                              onClick={handleShow}
                              style={{ cursor: 'pointer' }}
                            />
                            <div class="bg-light p-2 slid-con">20 x 60 North facing 2 story floor plan Created by AI</div>
                            {/* For Image One */}
                            <Modal show={show} onHide={handleClose}>
                               <Modal.Header closeButton>
                              </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-1.webp"
                                  alt="Image 1 Modal"
                                />
                                 <div class="bg-light p-2 slid-con">20 x 60 North facing 2 story floor plan Created by AI</div>
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>
                      <Carousel.Item >
                          <div>
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/Elivation-1.webp"
                              alt="Image 1"
                              onClick={handleShowD}
                              style={{ cursor: 'pointer' }}
                            />
                            <div class="bg-light p-2 slid-con">{t("3D Elevation created by AI")}</div>
                            {/* For Image One */}
                            <Modal show={showD} onHide={handleCloseD}>
                               <Modal.Header closeButton>
                              </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/Elivation-1.webp"
                                  alt="Image 1 Modal"
                                />
                                 <div class="bg-light p-2 slid-con">{t("3D Elevation created by AI")}</div>
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>
                      <Carousel.Item>
                          <div>
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-2.webp"
                              alt="Image 2"
                              onClick={handleShowTwo}
                              style={{ cursor: 'pointer' }}
                            />
                             <div class="bg-light p-2 slid-con">30 x 60 North facing 2 story floor plan Created by AI</div>
                            {/* For Image Two */}
                            <Modal show={showTwo} onHide={handleCloseTwo}>
                               <Modal.Header closeButton>
                               </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-2.webp"
                                  alt="Image 2 Modal"
                                />
                                 <div class="bg-light p-2 slid-con">30 x 60 North facing 2 story floor plan Created by AI</div>
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>

                      <Carousel.Item>
                          <div>
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/elivation-2.webp"
                              alt="Image 2"
                              onClick={handleShowTwoD}
                              style={{ cursor: 'pointer' }}
                            />
                             <div class="bg-light p-2 slid-con">{t("3D Elevation created by AI")}</div>
                            {/* For Image Two */}
                            <Modal show={showTwoD} onHide={handleCloseTwoD}>
                               <Modal.Header closeButton>
                               </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/elivation-2.webp"
                                  alt="Image 2 Modal"
                                />
                                 <div class="bg-light p-2 slid-con">{t("3D Elevation created by AI")}</div>
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>

                      <Carousel.Item>
                          <div >
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-3.webp"
                              alt="Image 1"
                              onClick={handleShowSix}
                              style={{ cursor: 'pointer' }}
                            />
                             <div class="bg-light p-2 slid-con">25 x 60 North facing 3 story floor plan Created by AI</div>
                            {/* For Image One */}
                            <Modal show={showSix} onHide={handleCloseSix}>
                               <Modal.Header closeButton>
                              </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-3.webp"
                                  alt="Image 1 Modal"
                                />
                                 <div class="bg-light p-2 slid-con">25 x 60 North facing 3 story floor plan Created by AI</div>
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>

                      <Carousel.Item>
                          <div >
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/elivation-3.webp"
                              alt="Image 1"
                              onClick={handleShowSixD}
                              style={{ cursor: 'pointer' }}
                            />
                             <div class="bg-light p-2 slid-con">{t("3D Elevation created by AI")}</div>
                            {/* For Image One */}
                            <Modal show={showSixD} onHide={handleCloseSixD}>
                               <Modal.Header closeButton>
                              </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/elivation-3.webp"
                                  alt="Image 1 Modal"
                                />
                                 <div class="bg-light p-2 slid-con">{t("3D Elevation created by AI")}</div>
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>

                      <Carousel.Item>
                          <div >
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-4.webp"
                              alt="Image 2"
                              onClick={handleShowSeven}
                              style={{ cursor: 'pointer' }}
                            />
                             <div class="bg-light p-2 slid-con">19 x 45 North facing 2 story floor plan Created by AI</div>
                            {/* For Image Two */}
                            <Modal show={showSeven} onHide={handleCloseSeven}>
                               <Modal.Header closeButton>
                                </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-4.webp"
                                  alt="Image 2 Modal"
                                />
                                 <div class="bg-light p-2 slid-con">19 x 45 North facing 2 story floor plan Created by AI</div>
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>

                      <Carousel.Item>
                          <div >
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/elivation-4.webp"
                              alt="Image 2"
                              onClick={handleShowSevenD}
                              style={{ cursor: 'pointer' }}
                            />
                             <div class="bg-light p-2 slid-con">{t("3D Elevation created by AI")}</div>
                            {/* For Image Two */}
                            <Modal show={showSevenD} onHide={handleCloseSevenD}>
                               <Modal.Header closeButton>
                                </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/elivation-4.webp"
                                  alt="Image 2 Modal"
                                />
                                 <div class="bg-light p-2 slid-con">{t("3D Elevation created by AI")}</div>
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>

                      <Carousel.Item>
                          <div>
                            <img
                              loading="lazy"
                              className="d-block w-100"
                              src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-5.webp"
                              alt="Image 5"
                              onClick={handleShowFive}
                              style={{ cursor: 'pointer' }}
                            />
                             <div class="bg-light p-2 slid-con">40 x 60 North facing 2 story floor plan Created by AI</div>
                            {/* For Image Two */}
                            <Modal show={showFive} onHide={handleCloseFive}>
                               <Modal.Header closeButton>
                                 </Modal.Header>
                              <Modal.Body>
                                <img
                                  loading="lazy"
                                  className="d-block w-100"
                                  src="https://mailer-assets.makemyhouse.com/checkout-page-images/plan-5.webp"
                                  alt="Image 2 Modal"
                                />
                              </Modal.Body>
                            </Modal>
                          </div>
                      </Carousel.Item>
                    </Carousel>
                    </div>
                  </div>
                </div>
              </section>

              <div className="faq p-3 pt-0 row">
                <div className="heading px-0">
                  <h2 className="text-center">
                    <b>{t("Frequently Asked Questions")}</b>
                  </h2>
                </div>
                <div className="col-sm-12">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {t("faq1")}
                      </Accordion.Header>
                      <Accordion.Body>
                        {t("faq1ans")}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                      {t("faq2")}
                      </Accordion.Header>
                      <Accordion.Body>
                      <p style={{fontWeight:"bold"}}>{t("faq2ans")}</p>
                      <p style={{fontWeight:"bold"}}>{t("faq2ans2")}</p>
                      <p style={{fontWeight:"bold"}}>{t("faq2ans3")}</p>
                      <p style={{fontWeight:"bold"}}>{t("faq2ans4")}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                      {t("faq3")}
                      </Accordion.Header>
                      <Accordion.Body>
                      {t("faq3ans")}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        {t("faq4")}
                      </Accordion.Header>
                      <Accordion.Body>
                      {t("faq4ans")}
                        <a href="mailto:contact@makemyhouse.com">
                          {t("contact@makemyhouse.com")}
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                      {t("faq5")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p><span style={{fontWeight:"bold"}}>{t("faq5ansH")} - </span>Rs.{data.after_offer_amount}/- ({data.discount}% OFF on Rs.{data.original_amount}/-) </p>
                        <p><span style={{fontWeight:"bold"}}>{t("faq5ans2H")} - </span>Rs.{data.second_after_offer_amount}/- ({data.second_discount}% OFF on Rs.{data.second_original_amount}/-) </p>
                        <p><span style={{fontWeight:"bold"}}>{t("faq5ans3H")} - </span>Rs.{data.ele_after_offer_amount}/- ({data.ele_discount}% OFF on Rs.{data.ele_original_amount}/-) </p>
                        <p><span style={{fontWeight:"bold"}}>{t("faq5ans4H")} - </span>Rs.{data.second_ele_after_offer_amount}/- ({data.second_ele_discount}% OFF on Rs.{data.second_ele_original_amount}/-) </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                      {t("faq6")}
                      </Accordion.Header>
                      <Accordion.Body>
                      {t("faq6ans")}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              <section className="price-main price-main-g mt-3 mb-5">
                <div className="row p-4 pt-2">
                  <div className="col-3">
                    <img
                      loading="lazy"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/money.png"
                      width="100%"
                      alt="currency"
                    ></img>
                  </div>
                  <div className="col-9">
                    <div className="heading mt-0 mb-0">
                      <h3>
                        <b>{t("100% Money Back Guarantee")}</b>
                      </h3>
                    </div>
                    <p className="mb-1">
                      {t("money back")}
                    </p>
                  </div>
                </div>
              </section>

              <div className="col-sm-12 mb-3 main-slider p-0">
                <Carousel data-bs-theme="dark">
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>{t("test1")}</b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                        {t("test1d")}
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>{t("test2")}</b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                        {t("test2d")}
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>{t("test3")}</b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                        {t("test3d")}
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>{t("test4")}</b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                        {t("test4d")}
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>{t("test5")}</b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                        {t("test5d")}
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>{t("test6")}</b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                        {t("test6d")}
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>

              <section className="mt-4 w-100">
                <div className="row p-3 pb-4 bg-secondary m-4">
                  <div className="col-12">
                    <div className="heading px-0">
                      <h3 className="text-center">
                        {t("Guaranteed")} <b>{t("Safe")}</b> {t("Checkout")}
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/pay-card.png"
                        width="100%;"
                        className="mt-3"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </section>

              <section className="mb-4 w-100">
                <div className="row pt-2">
                  <div className="col-12">
                    <div className="heading px-0">
                      <h3 className="text-center">
                        <b>{t("As Featured in")}</b>
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/db-logo.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/zeenews.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/The_Pioneer-logo.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                    <br></br>
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/dna-logo.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/outlookmagzine.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </section>

              <section className="mt-3">
                <div className="row">
                  <div className="col-12">
                    <div className="accolades bg-light p-3 m-2 w-75 m-auto text-center">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/google-r.png"
                        width="70%"
                        alt=""
                      ></img>
                    </div>
                    <br></br>
                    <div className="accolades bg-light p-3 m-2 w-75 m-auto mt-2">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img
                            loading="lazy"
                            src="https://mailer-assets.makemyhouse.com/checkout-page-images/award_realestate.webp"
                            width="65%"
                            alt=""
                          ></img>
                        </div>
                        <div className="col-8">
                          <h6 className="text-dark">
                            <b>
                              {t("Awards")}
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="accolades bg-light p-3 m-2 w-75 m-auto mt-2">
                      <div className="row p-2">
                        <div className="col-4">
                          <img
                            loading="lazy"
                            src="https://mailer-assets.makemyhouse.com/checkout-page-images/award_red.webp"
                            width="100%"
                            alt=""
                          ></img>
                        </div>
                        <div className="col-8">
                          <h6 className="text-dark">
                            <b>{t("Red Achievers Awards 2021")}</b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="accolades bg-light p-3 m-2 w-75 m-auto mt-2 mb-2">
                      <div className="row p-2">
                        <div className="col-4">
                          <img
                            loading="lazy"
                            src="https://mailer-assets.makemyhouse.com/checkout-page-images/startupaward.png"
                            width="100%"
                            alt=""
                          ></img>
                        </div>
                        <div className="col-8">
                          <h6 className="text-dark mb-0">
                            <b>{t("Startup Awards 2022")}</b>
                          </h6>
                          <p className="mb-0 text-dark">
                            {t("Awarded As Top 25 Startups in Indore by *Government of India*")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="my-3 px-2 mt-4">
                <div className="heading">
                  <h3 className="text-sm text-center">
                    {" "}
                    <b>{t("*Disclaimer")}</b>{" "}
                  </h3>
                </div>
                <p className="text-sm text-center">
                  <i>
                    {t("DisHeading")}
                    <a href="mailto:contact@makemyhouse.com">
                      {t("contact@makemyhouse.com")}
                    </a>{" "}
                  </i>
                </p>
              </div>

              <section className="w-100">
                <div className="">
                  <div className="col-12 text-center mb-1">
                    <a href="https://www.facebook.com/MakeMyHousePlans/">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/fb.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                    &nbsp;
                    <a href="https://www.instagram.com/makemyhouseindore/?hl=en">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/insta.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                    &nbsp;
                    <a href="https://www.youtube.com/c/Makemyhouse/videos">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/youtube.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                    &nbsp;
                    <a href="https://www.linkedin.com/company/make-my-house/?viewAsMember=true">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/linkdin.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                    &nbsp;
                    <a href="https://twitter.com/makemyhouse?s=09">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/tw.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </section>
              <section className="w-100 mt-3">
                <div className="text-center">
                  <div className="col-12 text-center mb-1">
                    <a href="https://www.makemyhouse.com/page/t&c">
                      {t("T&C")} &nbsp; |
                    </a>

                    <a href="https://www.makemyhouse.com/page/privacy-policy">
                      &nbsp; {t("Privacy Policy")} &nbsp; |
                    </a>

                    <a href="https://www.makemyhouse.com/page/disclaimer">
                      &nbsp; {t("Disclaimer")}
                    </a>
                  </div>
                </div>
              </section>
              <section className="w-100">
                <div className="row">
                  <div className="col-12 text-center mb-2 text-white">
                    <font>
                      An initiative by{" "}
                      <a href="https://www.makemyhouse.com/">Makemyhouse.com</a>
                    </font>
                  </div>
                </div>
              </section>
              <section className="w-100 mb-5">
                <div className="row">
                  <div className="col-12 text-center text-white">
                    <font>
                      © Copyright 2025{" "}
                      <a href="https://www.makemyhouse.com/">Make my house</a>{" "}
                      All Rights Reserved.
                    </font>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {planValue === "one" && (
            <a
              onClick={() => updatePlaneInResponse(payURL, "Option1")}
              id="firstPayment"
              className="cursor_pointer"
            >
              <div className="pay-button ">
                {t("Pay")} {data.symbol}
                {data.after_offer_amount} {t("To Unlock Now")}
              </div>
            </a>
          )}

          {planValue === "two" && (
            <a
              onClick={() => updatePlaneInResponse(secondPayUrl, "Option2")}
              id="secondPayment"
              className="cursor_pointer"
            >
              <div className="pay-button">
              {t("Pay")} {data.symbol}
                {data.second_after_offer_amount} {t("To Unlock Now")}
              </div>
            </a>
          )}

          {planValue === "three" && (
            <a
              onClick={() => updatePlaneInResponse(thiredPayUrl, "Option1_2D_3D")}
              id="secondPayment"
              className="cursor_pointer"
            >
              <div className="pay-button">
              {t("Pay")} {data.symbol}
                {data.ele_after_offer_amount} {t("To Unlock Now")}
              </div>
            </a>
          )}

          {planValue === "four" && (
            <a
              onClick={() => updatePlaneInResponse(fourthPayUrl, "Option2_2D_3D")}
              id="secondPayment"
              className="cursor_pointer"
            >
              <div className="pay-button">
              {t("Pay")} {data.symbol}
                {data.second_ele_after_offer_amount} {t("To Unlock Now")}
              </div>
            </a>
          )}
        </section>
      )}
    </>
  );
};

export default StepThree;
