import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppA from './components/AppA/AppA';
import AppB from './components/AppB/AppB';
import ThankYou from "./components/ThankYou/ThankYou";
import PaymentFailed from "./components/PaymentFailed/PaymentFailed";
import StepThree from './components/AppB/StepThree';
import { Toaster } from 'react-hot-toast';
// import { useTranslation } from 'react-i18next';

// import StepForm from './components/AppB/StepForm';

function App() {
  // const { i18n } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  return (
    <Router>
      
      <Toaster />
        {/* Language Selector */}
        {/* <div>
          <button style={{marginRight:"1rem",marginTop:"5px",marginBottom:"5px"}} onClick={() => changeLanguage('en')}>English</button>
          <button style={{marginRight:"1rem",marginTop:"5px",marginBottom:"5px"}} onClick={() => changeLanguage('hn')}>Hindi</button>
          <button style={{marginRight:"1rem",marginTop:"5px",marginBottom:"5px"}} onClick={() => changeLanguage('ta')}>Tamil</button>
        </div> */}
      <Routes>
        
        {/* Define route for AppA */}

        <Route exact path="/" element={<AppA />} />
        <Route  path="/survey-form" element={<AppA />} />
        <Route  path="/thank-you" element={<ThankYou />} />
        <Route  path="/payment-failure" element={<PaymentFailed />} />
        <Route  path="/response/:slug" element={<AppB />} />
        <Route  path="/response/checkout/:slug" element={<StepThree />} />


        {/* For US  */}
        <Route  path="/us" element={<AppA />} />
        <Route  path="/us/thank-you" element={<ThankYou isUs={true} />} />
        <Route  path="/us/response/:slug" element={<AppB />} />
        <Route  path="/us/response/checkout/:slug" element={<StepThree />} />


        {/* For UK  */}

        <Route  path="/uk" element={<AppA />} />
        <Route  path="/uk/thank-you" element={<ThankYou isUs={true} />} />
        <Route  path="/uk/response/:slug" element={<AppB />} />
        <Route  path="/uk/response/checkout/:slug" element={<StepThree />} />
        

      </Routes>
    </Router>
  );
}

export default App;
