import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StepTwo = ({ otp, setOtp, handleVerifyOtp, isLoading,handleNext }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <section className="main-bdy">
      <div className="step">
        <h2>
            <ArrowBack

              onClick={handleNext}
              style={{ display: "flex" }}
            />
         
          {t("Enter OTP")}
        </h2>
        <input
          type="text"
          name="otp"
          value={otp}
          onChange={handleOtpChange}
          placeholder={t("Enter OTP")}
        />
        { (window.location.href.includes("/us") || window.location.href.includes("/uk")) ? <p style={{ color: "red" }}>{t("login form msg")}</p> : '' }

        {isLoading ? (
          <button>{t("Verifying OTP")}..</button>
        ) : (
          <button onClick={handleVerifyOtp}>{t("Submit")}</button>
        )}
      </div>
    </section>
  );
};

export default StepTwo;
