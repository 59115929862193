import React,{useState} from 'react';
import Modal from "react-bootstrap/Modal";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OverlayTrigger, Popover} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const DiscountModal = ({show,change,min,sec,payUrl,apiCall,timer,base_prize,offer_prize,symbol}) => {
  const { t} = useTranslation();
  const popoverOffer = (
    <Popover id="popover-basic-second">
      <Popover.Body>
      {t("You will receive One Floor Plan + 3D Elevation options")}
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <Modal
        className="modal fade modal-bottom-to-top"
        id="lab-slide-bottom-popup-2"
        show={show}
        onHide={change}
        backdropClassName="modal-backdrop"
        centered
      >
                    <Modal.Header closeButton><Modal.Title></Modal.Title> </Modal.Header>
                      <div className="lab-modal-body">
                        <div className="content_new">
                          <h1 className="spcl_offer">{t("Special Discount Applied")}</h1>
                          {/* add counter */}
                          <section className="timeContainer">
                            <div className="circleDiv"></div>
                            <div className="wrapper">
                              <div className="minutes">
                                <h2 id="minutes">{min.toString().padStart(2, '0')}</h2>{t("MINUTES")}
                              </div>
                              <div className="seconds">
                                <h2 id="seconds">{sec.toString().padStart(2, '0')}</h2>SECONDS
                              </div>
                            </div>
                          </section>
                          <section className="price-main mt-3 add_popupop">
                            <div className="" style={{ padding: "0px 10px 20px 10px" }}>
                              <div className="col-12 text-left">
                                <div className="heading mt-0">
                                  <h3>
                                    <b>{t("Your 2D Floor Plan is ready !")}</b>
                                  </h3>
                                </div>
                                <p className="mb-3 price_descn">
                                  {t("2d Heading")}
                                </p>
                                <p className="mb-1 price_descn">
                                  <strong>{t("Delivery Time")} :</strong> {t("Within 4 working hours")}
                                </p>
                                <p className="mb-1 price_descn">
                                  <strong>{t("Delivery Via")} :</strong> {t("Softcopy (Email , Whatsapp)")}
                                </p>
                              </div>
                              <div className="plans mt-2 text center">
                                {/* For First Plane  */}
                                <label className="plan basic-plan" htmlFor="basic">
                                      <input  type="radio" name="plan" />
                                  <div className="plan-content new_card">
                                    <div className="offer_flex">
                                    <div className="bluprint-ai">
                                      <p className="infotitle_ai">
                                      {t("faq5ans3H")}
                                      </p>
                                      <OverlayTrigger
                                        
                                        placement="bottom"
                                        overlay={popoverOffer}
                                      >
                                        <a variant="secondary">
                                          <InfoOutlinedIcon
                                            style={{
                                              fontSize: "14px",
                                              marginLeft: "4px",
                                            }}
                                          ></InfoOutlinedIcon>
                                        </a>
                                      </OverlayTrigger>
                                    </div>
                                      <div className="col-6_ai">
                                        <p className="title_price new_399">
                                          <strong>{t("One(2D Design + 3D Elevation) option plan")}:</strong>
                                        </p>
                                      </div>
                                      <div className="col-6_ai offer_price">
                                        <div className="price showprice d-flex add_price" style={{color: "#000 !important"}}>
                                          <div>
                                            {symbol}
                                            {offer_prize}/-
                                          </div>{" "}
                                          &nbsp;{" "}
                                          <s style={{ color: "#696969" }}>
                                            {symbol}
                                            {base_prize}
                                          </s>
                                        </div>
                                        <div className="col-6_ai ">
                                          <p className="tag_front">{t("Best Seller")}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                {/* End First Plan Div  */}
                                <style>
                                  {`
                                    .right-float-white {
                                      color: white !important;
                                      display: flex;
                                      justify-content: end;
                                      align-items: center;
                                    }
                                    .cursor_pointer{
                                      cursor:pointer !important;
                                    }
                                  `}
                                </style>
                              </div>
                            </div>
                          </section>
                          <div className="using_CONTENT">
                            <div className="grow_img">
                              <TrendingUpIcon style={{fontSize: "14px", marginLeft: "4px"}}></TrendingUpIcon>
                            </div>
                            <p>{t("50+ People Bought Designs in Past Half an Hours")}</p>
                          </div>
                          {timer && (<>
                            <a onClick={()=>apiCall(payUrl,"Option2_Offer")} id="firstPayment" className="cursor_pointer">
                              <button className="btn btn-sm btn_pay w-100">
                                {t("Pay Now")}
                              </button>
                            </a>
                          </>)}
                           
                        </div>
                      </div>
      </Modal>
    </>
  );
};
export default DiscountModal;